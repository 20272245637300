import Lottie from "lottie-react";
import { BiHomeAlt } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
// import { GiHoneycomb } from "react-icons/gi";
import { ImBlog } from "react-icons/im";
import { Link, useLocation } from "react-router-dom";
import whatsapp from "../../../Assets/Icon/whatsapp-nav.json";
import "./Navbar.css"
import { useEffect, useState } from "react";

export const Navbar = () => {
  const { pathname } = useLocation();

  const [bg, setBg] = useState(false);
  const [show, setSHow] = useState(true);



  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.outerWidth > 640) {
        return window.scrollY > 70 ? setBg(true) : setBg(false);
      }
    });

  });


  const activePathCheck = () => {
    const allRoutes = document.querySelectorAll('[data-type="route"]');

    let hasActive = document.querySelector(".active-path");
    if (hasActive) {
      hasActive.classList.remove("active-path");
    }

    for (let route of allRoutes) {
      const path = route.getAttribute("href");
      if (path === pathname) {
        route.classList.add("active-path");
      }
    }
  };
  activePathCheck();

  return (
    <div
      data-attr="navbar"
      className={`fixed sm:sticky sm:top-0 sm:right-0 sm:left-0 sm:mx-auto mr-20  bottom-0 sm:bottom-[unset] sm:w-[100%] w-full  lg:h-[72px] md:h-[55px] h-[64px] z-[999] sm:px-12 px-0 overflow-hidden transition-all duration-500 bg-[var(--secondary-color)] ${bg ? "sm:bg-[var(--primary-color)] nav-shadow" : "sm:bg-transparent"} ${show ? "translate-y-0" : "translate-y-[-72px]"}`}
    >
      <div className="flex justify-between items-center h-full max-w-[1300px] mx-auto">
        <Link
          to="/"
          className="hidden sm:block -mb-[12px] cinzel font-semibold text-[26px] cursor-none"
        >
          ThENEMO
        </Link>

        <div className="flex justify-center sm:items-start mx-auto sm:mx-[unset] items-center w-fit space-x-[18px] lg:space-x-[48px] xl:space-x-[88px] text-[var(--variant-gold)] sm:text-[var(--secondary-color)]">
          <div>
            <Link
              to="/"
              data-type="route"
              className="lg:text-3xl md:text-2xl text-xl nav-selection type-1"
            >
              <BiHomeAlt />
            </Link>
          </div>

          <div>
            <Link
              to="/neyaz-posts"
              data-type="route"
              className="lg:text-3xl md:text-2xl text-xl nav-selection type-1"
            >
              <ImBlog />
            </Link>
          </div>

          <div>
            <Link
              to="/contact"
              data-type="route"
              className="lg:text-3xl md:text-2xl text-xl nav-selection type-1"
            >
              <FiMail />
            </Link>
          </div>

          <div className="lg:mt-5 md:mt-4 block sm:hidden">
            <a
              href="https://wa.me/+8801303246616"
              target="_blank"
              rel="noreferrer"
              className="lg:text-3xl md:text-2xl text-xl nav-selection type-1 md:-mt-5"
            >
              <Lottie animationData={whatsapp} className="lg:h-11 md:h-9 h-8" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
