import { IoIosArrowUp } from "react-icons/io";
import { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";

export const BackToTop = () => {
  let value = 700;
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let currentScroll = window.scrollY + window.innerHeight;
      let documentHeight = document.body.scrollHeight;

      return currentScroll + 248 > documentHeight ? setShow(true) : setShow(false);
    });
  });

  const scrollToTop = () => {scroll.scrollToTop()};

  const scrollToBottom = () => {
    scroll.scrollTo(value);
    value += 700;
  };

  return show ? (
    <button duration={100} onClick={() => scrollToTop()}
      className="bounce text-3xl w-8 h-10 rounded-sm fixed sm:right-6 right-2 bottom-[70px] sm:bottom-[24px] duration-[250ms] z-[999]">
      <div className="text-[var(--secondary-color)] font-bold ">
        <IoIosArrowUp />
        <IoIosArrowUp className="-mt-[37px]" />
      </div>
      <span hidden>Scroll</span>
    </button>
  ) : (
    <button duration={100} onClick={() => scrollToBottom()}
      className="scroll-btn fixed sm:right-6 right-2 bottom-[70px] sm:bottom-[24px] z-[999]">
        <div className="scroll-btn-design"><span hidden>Scroll</span></div>
    </button>
  );
};
