import style from "./Clock.module.css";

export const Clock = () => {
  const clockCount = () => {
    const hours = document.querySelector("#hours");
    const minutes = document.querySelector("#minutes");
    const seconds = document.querySelector("#seconds");

    let today = new Date();
    let hr = (today.getHours() % 12) + today.getMinutes() / 59; // 22 % 12 = 10pm
    let mnt = today.getMinutes(); // 0 - 59
    let s = today.getSeconds(); // 0 - 59

    hr *= 30; // 12 * 30 = 360deg
    mnt *= 6;
    s *= 6; // 60 * 6 = 360deg

    rotation(hours, hr);
    rotation(minutes, mnt);
    rotation(seconds, s);

    // call every second
    setTimeout(clockCount, 500);
  };

  const rotation = async (target, value) => {
    if (target && value) {
      target.style.transform = `rotate(${value}deg)`;
    }
  };

  return (
    <div onLoad={clockCount()}>
      <div className={`${style.clock}`}>
        <div className={`${style.hand} ${style.hours} `} id="hours"></div>
        <div className={`${style.hand} ${style.minutes} `} id="minutes"></div>
        <div className={`${style.hand} ${style.seconds}`} id="seconds"></div>
        <div className={`${style.point}`}></div>
        <div className={`${style.marker}`}></div>
      </div>
    </div>
  );
};
