import Aos from "aos";
import "aos/dist/aos.css";
import Router from "./Router";
import { Toaster } from "react-hot-toast";
import { useLoading } from "./Hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { BackToTop, Loading, Navbar } from "./Components";
import bitlaab from "./Assets/bitlaab.svg";

function App() {
  const [loading] = useLoading();
  const { pathname } = useLocation();
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  Aos.init({ duration: 1200, mirror: false });

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="">
            {/* top loading bar */}
            <LoadingBar
              color="#292524"
              height={4}
              progress={progress}
              onLoaderFinished={() => setProgress(0)}
            />

            {pathname === "/" ||
            pathname === "/neyaz-posts" ||
            pathname === "/contact" ? (
              <Navbar />
            ) : (
              ""
            )}

            <Router />
            {pathname !== "/contact" ? <BackToTop /> : ""}
            <Toaster />
          </div>

          <div className="bg-[var(--secondary-color)] text-[var(--variant-light-gold)] py-2 hidden sm:block">
            <div className="flex items-center justify-center sm:justify-between max-w-[1300px] mx-auto ">
              <p className="text-[12px]">
                Copyright © {new Date().getFullYear()} neyaznafiz | All rights
                reserved
              </p>

              <a href="https://bitlaab.com/" target="_blank" rel="noreferrer">
                  <img src={bitlaab} alt="" className="w-[72px]" />
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
