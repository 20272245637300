import Lottie from "lottie-react";
import developer from "../../../Assets/opening-developer.json";

export const RandomFacts = () => {
  return (
    <div>
      <div className="h-fit mt-10 sm:mt-0 p-4 w-fit mx-auto flex flex-col md:flex-row items-center justify-center gap-[18px] md:gap-[48px] lg:gap-[72px]">
        <Lottie animationData={developer} className="w-[300px] md:w-[400px] lg:w-[464px] -my-12 lg:my-0 md:-mt-24" />

        <div>
          <h1 className="text-[20px] sm:text-[24px] font-medium mb-3 whitespace-nowrap">Random Facts</h1>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">Love to play with program for recreation</p>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">Reading/Learning is my zen time</p>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">Love to eat, but I can't cook</p>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">I drink a lot of coffee</p>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">I love the beauty of code</p>
          <p className="text-[14px] sm:text-[16px] pb-[3px]">I've a bit of affection for rabbit</p>
          <p className="text-[14px] sm:text-[16px] pb-[20px]">I'm slightly addicted to playing games</p>
        </div>
      </div>
    </div>
  );
};
