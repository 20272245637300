import { GiClick } from "react-icons/gi";
import { useInView } from 'react-intersection-observer';
import "./displayAllProject.css";
import { useEffect } from "react";

export const DisplayAllProject = ({ project }) => {
  const { name, img, live } = project || {};

  const { ref, inView } = useInView({ threshold: 0.2, });

  return (
    <div ref={ref} className={`w-full relative hover:z-[999]`} data-attr="display-all-project">
      <a
        href={live}
        target="_blank"
        rel="noreferrer"
        className={`project-card capitalize relative z-0 text-[24px] sm:text-[28px] font-semibold pb-[12px] mb-[12px] text-[var(--text-color)] border-b-2 border-[var(--text-color)] w-full flex justify-between items-center pr-2 hover:text-[var(--variant-gold)] hover:border-[var(--variant-gold)] hover:duration-[350ms] transition-[transform, color] duration-[1000ms] ease ${inView ? "-translate-x-[0vw]" : "-translate-x-[100vw]"}`}>
        {name} <GiClick className="ml-2" />

        <div id="image-box" className="details-box absolute h-0 w-[264px] sm:w-[400px] right-[38px] sm:right-[52px] bottom-0 z-[99] overflow-hidden box-border">
          <img src={img} alt="Project" className="block absolute bottom-0 rounded-tl-[10px] rounded-tr-[10px]" />
        </div>
      </a>
    </div>
  );
};
