import React from "react";
import "./Rabbit.css";

export function Rabbit() {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="rabbit"></div>
      <div className="clouds"></div>
    </div>
  );
}
