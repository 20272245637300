import "./Brain.css";
import brain from "../../../Assets/brain.svg";
import { useInView } from 'react-intersection-observer';

export const Brain = () => {
  const { ref, inView } = useInView({ threshold: 0.2, });

  return (
    <div ref={ref} className="p-4" data-attr="brain">
      <div className="xl:w-[86%] w-full mx-auto flex flex-col sm:flex-row items-center justify-center gap-[24px] lg:gap-[48px]  relative z-10">
        <div className={`text-center sm:text-end`}>
          <h1 className={`text-[20px] sm:text-[24px] font-medium mb-3 whitespace-nowrap transition-all duration-[1000ms] ease ${inView ? "opacity-1000" : "opacity-0"}`}>Part Programmer</h1>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-200 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Web Automation</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-300 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Backend Development</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-500 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Front-end Development</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-700 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Software, Website, Mobile Application</p>
        </div>

        <div className="brain-img max-w-[200px] lg:max-w-[264px] w-full h-fit">
          <img src={brain} alt="" className={`max-w-[200px] lg:max-w-[300px] w-full h-fit`}/>
        </div>

        <div className={`text-center sm:text-start`}>
          <h1 className={`text-[20px] sm:text-[24px] font-medium mb-3 whitespace-nowrap transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Part Designer</h1>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-200 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>UI Design</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-300 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>UX Design</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-500 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>Design Systems</p>
          <p className={`text-[14px] sm:text-[16px] pb-[3px] delay-700 transition-all duration-[1000ms] ease ${inView ? "opacity-100" : "opacity-0"}`}>
            Interaction Design, Creative Thinking
          </p>
        </div>
      </div>
    </div>
  );
};
