import "./AboutMe.css";
import neyazImg from "../../../Assets/neyaz.jpg";

export function AboutMe() {
  return (
    <div data-attr="about-me" className="sm:mt-20 my-10">
      <div className="width mx-auto mb-[48px] md:mb-[112px] p-4">
        <div className="flex md:flex-row flex-col gap-[24px] justify-between items-center lg:w-[84%] w-full mx-auto">
          <div className="max-w-[348px] md:max-w-[564px] h-full overflow-hidden rounded-tr-[24px] rounded-bl-[24px] rounded-tl-[5px] rounded-br-[5px]">
            <img src={neyazImg} alt="Neyaz" className="w-full h-full object-contain"/>
          </div>

          <div className=" w-full">
            <h2 className="cinzel bg-ast text-center text-[36px] lg:text-[42px] mb-3 md:mb-0 ">
                <span className="text-[48px] lg:text-[64px] text-[var(--variant-gold)]">H</span>
              ey There <span className="text-[48px] lg:text-[64px]">!</span>
            </h2>

            <div id="about-text" className="md:px-[36px] md:pt-[28px]">
              <p className=" text-[14px] md:text-[16px] text-center">
                This is Neyaz. My areas of expertise are Software and Web
                Application Development, UI/UX Design, as well as Web
                Automation. Currently, I am working at
                <a href="https://bitlaab.com/" target="_blank" rel="noreferrer" className="font-semibold ml-[4px]">bitlaab</a>
                , a cutting-edge software solution, with a belief in harnessing
                the power of technology to drive positive change and shaping the
                future through innovation and technology. <br /> <br /> I have a
                passion for my career and look forward to continuing to grow
                myself by converting my technical expertise and efforts into a
                successful business. <br />
                <br /> It would be a pleasure for me to work with people who
                take challenges and want to take this living world to a
                different aspect.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
