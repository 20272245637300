import { useState } from "react";
import { Rabbit } from "../Components";
import panda from "../Assets/panda.svg";
import error from "../Assets/error.gif";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaArrowLeft } from "react-icons/fa";

export const NotFound = () => {
  const [startingAnimation, setStartingAnimation] = useState(true);
  const [pageState, setPageState] = useState(false);

  const pageContentToggle = () => {
    setTimeout(() => {
      setPageState(true);
      setStartingAnimation(false);
    }, 1200);
  };

  return (
    <div className="w-screen h-[calc(100vh-29px)] sm:h-[calc(100vh-32px)]" data-attr="404-page" data-page={pageState} onLoad={pageContentToggle()} data-barba="wrapper" data-barba-namespace="blog">

      {/* seo */}
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta name="description" content="404 - Page not found." />
        <link rel="canonical" href="*" />
      </Helmet>


      {/* Starting Animation */}
      <div
        id="starting-animation"
        data-starting-animation={startingAnimation}
        className="absolute top-0 h-screen w-screen bg-[var(--primary-color)] z-[9999]"
      >
        <Rabbit />
      </div>

      <div className="content-wrapper mx-auto max-w-[1080px] w-full h-full flex flex-col justify-center items-center relative">
        <img src={panda} alt="" className="w-[200px] sm:w-[448px] h-fit -ml-10 sm:-ml-20"/>
        <img src={error} alt="" className="w-[200px] sm:w-[300px] h-fit" />

        <Link
          to="/"
          className="flex items-center gap-2 text-[14px] sm:text-[16px] px-4 py-1 border-[1px] border-[var(--secondary-color)] text-[var(--secondary-color)] mt-5 hover:bg-[var(--secondary-color)] hover:text-[var(--variant-light-gold)] transition-all duration-[250ms]"
        >
          <FaArrowLeft />
          Back to Neyaz's Portfolio
        </Link>
      </div>
    </div>
  );
};
