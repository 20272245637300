export const MyInfo = () => {
  return (
    <div className="max-w-[750px] w-full mx-auto text-center flex flex-col items-center justify-center h-full gap-y-4 p-4 pt-16 sm:pb-16 pb-0">
      <p className="sacramento text-[26px] sm:text-[40px] leading-[40px] sm:leading-[60px]">
        “There are always some bugs in you code, in your life, also Everywhere. Just keep
        debugging.”
      </p>
    </div>
  );
};
