import "./project.css";
import { GiClick } from "react-icons/gi";
import { useEffect, useState } from "react";
import { DisplayAllProject } from "./DisplayAllProject";

export const Projects = () => {
  const [pro, setPro] = useState([]);

  useEffect(() => {
    fetch("projects.json", )
      .then((res) => res.json())
      .then((data) => setPro(data));
  }, []);

  return (
    <div data-attr="projects">
      <div className=" flex flex-col lg:flex-row justify-center gap-[64px] sm:gap-[96px] mx-auto max-w-[1300px]">
        {/* project */}
        <div className="w-full">
          <div className="lg:mb-[48px] mb-[36px] relative z-10">
            <h2 className="text-3xl lg:text-4xl cinzel tracking-wider font-semibold border-b-2 border-[var(--variant-gold)] text-[var(--variant-gold)] pb-3 ml-0">
              Showcase
            </h2>
          </div>

          <div className="flex flex-col">
            {pro.map((project) => (
              <DisplayAllProject key={project.id} project={project} ></DisplayAllProject>
            ))}
          </div>
        </div>

        {/* product */}
        <div className="w-full relative z-10">
          <div className="lg:mb-[48px] mb-[36px]">
            <h2 className="text-3xl lg:text-4xl cinzel tracking-wider font-semibold border-b-2 border-[var(--variant-gold)] text-[var(--variant-gold)] pb-3 ml-0">
              Package
            </h2>
          </div>

          <div className="flex flex-col text-[var(--text-color)]">
            <h1 className=" text-[24px] sm:text-[28px] font-semibold">System Diskinfo</h1>

            <p className="text-[14px] sm:text-[16px] my-[18px]">
              A package for Rust Developer to collect information about disk drive means SSD/HDD
              etc of a operating system. You will be able to collect
              disk information from any device with just a function call. you
              will be able to collect the disk name, disk model, disk size and
              disk serial number information of a operating system with this
              package.
            </p>

            <p><span className="font-semibold">NOTE:</span> Only Windows Version available now, MAC and Linux version will be available soon.</p>

            <a href="https://crates.io/crates/system_diskinfo" target="_blank" rel="noreferrer" className="mt-[24px] w-fit ml-auto cm-selection type-4 flex items-center">Read The Documentation <GiClick className="ml-2" /></a>
          </div>
        </div>
      </div>
    </div>
  );
};
